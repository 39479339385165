import React, { Component } from 'react';
import _ from "lodash";
import PropTypes from "prop-types";
import withValidations from '../WithValidations';
import { ErrorMessage, StyledColumnV2, FormContainer } from '../LoginPage/styles';
import { Heading, Text, FormHeading, FormWrapper, InputContainer, Button, CustomInput, ForgetCompany } from './styles';
import { withTranslation } from 'react-i18next';
import ForgetCompanyPopUp from './ForgetCompanyPopUp';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../utils/config';
import { ForgotCompany } from '../../redux/constants/apiConstants';
import CommonButton from '../common/CommonButton/CommonButton';
import { SsoKey } from '../../utils/icons';

class FormComponentV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
      showCompanyForgetPopup:false,
      showAlertPopup:false,
      showConfirmPopup:false,
      loading:false, 
      isWolibaUser:false,
      errMsg:""
    }
  }
  onInputChange = (e) => {
    const { onChange, changeSubmit } = this.props;
    changeSubmit('');
    onChange(e);
  };
  togglePassword = () => {
    this.setState((prevState) => ({
      display: !prevState.display
    }));
  };

  forgotCompany = async(email)=>{

    this.setState({ loading: true });
    let url = BASE_URL + ForgotCompany;
    await fetch(url, {
      method:"POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({email:email}),
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            window.console.log("data",data);
            
            if(!data.data.is_woliba_user){
              if(data.data.is_verified){
                this.setState({showConfirmPopup:true})
              }else{
                this.setState({showAlertPopup:true})
              }
              this.setState({showCompanyForgetPopup:false,loading:false});
            }else{
              this.setState({isWolibaUser:data.data.is_woliba_user,errMsg:"User is already registered",loading:false})
            }

          })
          .catch((error) => {
            this.setState({loading:false});
            toast.error(error);
          });
      })
      .catch((error) => {
        this.setState({loading:false});
        toast.error(error);
      });

  }
  render() {
    const { display, showCompanyForgetPopup, showConfirmPopup, showAlertPopup } = this.state;
    const { showResetPassword, section, errors, touched, onSubmit, loginError, type, companyError, isSubmitted, showLoginorRegister,t,setSso, buttonStatus } = this.props;
    return (
      <StyledColumnV2 marginLeft={section.title.includes('login')} marginRight={!section.title.includes('login')}>
        <Heading>{t(`${section.title}`)}</Heading>
        {section.type == "login" ||  section.type == "sso" ?  <Text>
          <div className="greyText">{t("New To Woliba?")}</div>&nbsp;
          <div className="blueText" onClick={() => showLoginorRegister()}>{t("Create An Account")}</div>
        </Text> :
          <Text>
            <div className="greyText">{t("Already Have An Account?")} </div>&nbsp;
            <div className="redText" onClick={() => showLoginorRegister()}>{t("Login")}</div>
          </Text>}
        <FormContainer>
          {
            type === 'login' ?
              (Object.keys(_.omitBy(errors, _.isNil)).length === 0 && isSubmitted === "user" &&
                loginError && <ul><ErrorMessage>{loginError}</ErrorMessage></ul>) :
              type === 'register' ? (Object.keys(_.omitBy(errors, _.isNil)).length === 0 &&
                isSubmitted === "company" && companyError && <ul><ErrorMessage>{companyError}</ErrorMessage></ul>) : ''
          }
          <form onSubmit={(e) => onSubmit(e)}>
            {
              section.inputFields.map((inputField, index) => (
                <FormWrapper key={inputField.placeholder + index}>
                  <FormHeading>
                    <div className="heading">{t(`${inputField.heading}`)}</div>
                    <div className="newPassword" onClick={showResetPassword}>{t(`${inputField.link}`)}</div>
                  </FormHeading>
                  <InputContainer >
                    <CustomInput
                      type={(inputField.type == "password") ? (display ? 'text' : inputField.type) : inputField.type}
                      placeholder={t(inputField.placeholder)}
                      name={inputField.name}
                      onChange={(e) => this.onInputChange(e)}
                      maxLength={inputField.maxLength}
                    />
                    {(inputField.type == "password") ? display ? <i className={`fas fa-eye eye-icon`} onClick={this.togglePassword} /> : <i className="far fa-eye-slash eye-icon" onClick={this.togglePassword} /> : null}
                    {errors[inputField.name] && <ul><ErrorMessage>{errors[inputField.name]}</ErrorMessage></ul>}
                  </InputContainer>
                </FormWrapper>
              ))
            }
            <div>
              <Button
                type="submit"
                background={section.buttonText === 'register'}
                disabled={
                  Object.keys(_.omitBy(errors, _.isNil)).length > 0
                  || Object.keys(touched).length === 0 ||
                  Object.keys(touched).length < section.inputFields.length || (type === "sso" && buttonStatus)
                }
              >{t(section.buttonText)}</Button>
            </div>
          </form>
          {type === "login" ? (<div style={{marginTop:"35px"}}>
            <CommonButton
              btnType={"squareIcon"}
              style={{color:"#007AB1", marginAuto:'auto', width:"100%",background:"rgba(0, 92, 135, 0.04)",border:"none",height:"50px",display:"flex",justifyContent:"center",alignItems:"center"}}
              onClick={() => setSso(false)}
              icon={SsoKey()}
              title={this.props.t("SSO")}
            />
          </div>):type === "sso" ? (<div style={{marginTop:"35px"}}>
            <CommonButton
              btnType={"squareIcon"}
              style={{color:"#007AB1", marginAuto:'auto', width:"100%",background:"rgba(0, 92, 135, 0.04)",border:"none",height:"50px"}}
              onClick={() => setSso(true)}
              title={this.props.t("Login with password")}
              // icon={SsoKey()}
            />
          </div>):null}
          {type == 'register' && <ForgetCompany>
            <span className='text' onClick={()=>this.setState({showCompanyForgetPopup:true})}>Don’t remember Company Name and Password?</span>
          </ForgetCompany>}
        </FormContainer>

        {showCompanyForgetPopup && (<ForgetCompanyPopUp type={"note"} title={"Note"} showModal={showCompanyForgetPopup} onNoClick={()=>this.setState({showCompanyForgetPopup:false})} onYesClick={this.forgotCompany} loading={this.state.loading} isWolibaUser={this.state.isWolibaUser} registerMsg={this.state.errMsg} setRegisterMsg={()=>this.setState({errMsg:""})}/>) }

        {showConfirmPopup && (<ForgetCompanyPopUp type={"confirm"} title={"Confirmation"} showModal={showConfirmPopup} onNoClick={()=>this.setState({showConfirmPopup:false})} onYesClick={this.forgotCompany}/>) }

        {showAlertPopup && (<ForgetCompanyPopUp type={"alert"} title={"Alert"} showModal={showAlertPopup} onNoClick={()=>this.setState({showAlertPopup:false})} onYesClick={this.forgotCompany}/>) }


      </StyledColumnV2>
    );
  }
}

FormComponentV2.propTypes = {
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showResetPassword: PropTypes.func.isRequired,
  loginError: PropTypes.string,
  type: PropTypes.string.isRequired,
  companyError: PropTypes.string,
  isSubmitted: PropTypes.string.isRequired,
  changeSubmit: PropTypes.func.isRequired,
  history: PropTypes.object,
  showLoginorRegister: PropTypes.func,
  t: PropTypes.func,
  setSso:PropTypes.func,
  buttonStatus:PropTypes.string

};

export default withValidations((withTranslation()(FormComponentV2)));
